import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import './ClusteredListings.css';
import api from '../api';
import DataTable from 'react-data-table-component';

mapboxgl.accessToken = 'pk.eyJ1IjoibWljaGFlbC1ydCIsImEiOiJjbTFmY2gxZGYydjBhMmptejRkMXY3NnE4In0.HLgD2rXqws3hQ0Wr7X7PQw';

const ClusteredListings = ({ selectedLocation, selectedDistance, selectedClient }) => {
  const [selectedCategory, setSelectedCategory] = useState('sale');
  const [selectedPropertyType, setSelectedPropertyType] = useState('Single Family');
  const [selectedBeds, setSelectedBeds] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(5);
  const [clusterData, setClusterData] = useState([]);
  const [map, setMap] = useState(null);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [transformedListings, setTransformedListings] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);

  const propertyTypes = {
    sale: {
      'Townhouse': [2, 3, 4],
      'Condo': [2, 3],
      'Single Family': [3, 4, 5]
    },
    rental: {
      'Apartment': [0, 1, 2, 3],
      'Townhouse': [2, 3, 4],
      'Condo': [2, 3],
      'Single Family': [3, 4, 5]
    }
  };
  

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!selectedLocation) return;

      try {
        const response = await api.get('/api/get-location-coordinates', {
          params: { locationId: selectedLocation },
        });
        setSelectedCoordinates(response.data);
      } catch (error) {
        console.error('Error fetching location coordinates:', error);
      }
    };

    fetchCoordinates();
  }, [selectedLocation]);

  const fetchChartsData = async () => {
    try {
      const response = await api.get(`/api/get_cluster_data`, {
        params: {
          locationId: selectedLocation,
          clusters: selectedCluster,
          searchDistance: selectedDistance,
          listingType: selectedCategory,
          productType: selectedPropertyType,
          beds: selectedBeds,
          latitude: selectedCoordinates.latitude,
          longitude: selectedCoordinates.longitude,
          yearsOld: 3
        },
        paramsSerializer: params => {
          return Object.entries(params)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
                : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join('&');
        }
      });
  
      console.log('Response data:', response.data);
      console.log('Type of response data:', typeof response.data);
  
      // // Parse the JSON string
      // const data = JSON.parse(response.data);
  
      // console.log('Parsed data:', data);
  
      const { listings_table, cluster_summary } = response.data;
  
      console.log('Listings Table:', listings_table);
      console.log('Cluster Summary:', cluster_summary);
  
      if (listings_table && listings_table.length > 0) {
        const transformedListingsData = transformListingsData(listings_table);
        setTransformedListings(transformedListingsData);
        initializeMap(listings_table);
      } else {
        setTransformedListings([]);
        clearMapMarkers();
      }
  
      if (cluster_summary && cluster_summary.length > 0) {
        const transformedClusterData = transformClusterData(cluster_summary);
        setClusterData(transformedClusterData);
      } else {
        setClusterData([]);
      }
  
    } catch (error) {
      console.error('Error fetching cluster data:', error);
      setTransformedListings([]);
      setClusterData([]);
      clearMapMarkers();
    }
  };

  useEffect(() => {
    if (selectedBeds.length > 0 && selectedPropertyType && selectedDistance && selectedCluster && selectedCoordinates) {
      fetchChartsData();
    }
  }, [selectedCategory, selectedPropertyType, selectedBeds, selectedDistance, selectedCluster, selectedLocation, selectedClient, selectedCoordinates]); // eslint-disable-line

  const clearMapMarkers = () => {
    mapMarkers.forEach(marker => marker.remove());
    setMapMarkers([]);
  };

  const initializeMap = (listingsData) => {
    if (!map) {
      const mapContainer = document.getElementById('map');
      if (mapContainer) {
        mapContainer.innerHTML = ''; // Clear previous map content if re-initializing
      }
  
      const mapInstance = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v11',
        center: [selectedCoordinates.longitude, selectedCoordinates.latitude],
        zoom: 14,
        antialias: true,
      });
  
      mapInstance.on('load', () => {
        setMap(mapInstance); // Set the map state after loading
        addMarkersToMap(listingsData, mapInstance);
      });
    } else {
      // Only add markers if the map is already initialized
      clearMapMarkers();
      addMarkersToMap(listingsData, map);
    }
  };

  const getClusterColor = (cluster) => {
    const colors = [
      'red', 'blue', 'green', 'orange', 'purple',
      'yellow', 'pink', 'brown', 'cyan', 'magenta',
      'lime', 'indigo', 'teal', 'violet', 'gold',
    ];
    return colors[cluster - 1] || 'gray';
  };

  const addMarkersToMap = (listingsData, mapInstance) => {
    clearMapMarkers();

    const transformedListings = transformListingsData(listingsData);

    const newMarkers = transformedListings.map(listing => {
      if (listing.longitude && listing.latitude) {
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundColor = getClusterColor(listing.cluster);
        el.style.width = '20px';
        el.style.height = '20px';
        el.style.borderRadius = '50%';
        el.style.border = '2px solid white';

        const popupContent = `
          <div>
            <strong>Address:</strong> ${listing.address} <br />
            <strong>Price:</strong> $${listing.price.toLocaleString()}
          </div>
        `;

        const marker = new mapboxgl.Marker(el)
          .setLngLat([listing.longitude, listing.latitude])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
          .addTo(mapInstance);

        el.addEventListener('click', () => {
          marker.togglePopup();
        });

        return marker;
      }
      return null;
    }).filter(marker => marker !== null);

    setMapMarkers(newMarkers);

    if (newMarkers.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      newMarkers.forEach(marker => {
        const lngLat = marker.getLngLat();
        bounds.extend(lngLat);
      });
      mapInstance.fitBounds(bounds, { padding: 50 });
    }
  };

  const transformListingsData = (listings) => {
    return listings.map(listing => {
      const latitude = listing.latitude;
      const longitude = listing.longitude;

      if (latitude === null || longitude === null) {
        return null;
      }

      return {
        listingId: listing.id || 'N/A',
        latitude: latitude,
        longitude: longitude,
        address: listing.formatted_address || 'N/A',
        bedrooms: listing.bedrooms || 'N/A',
        bathrooms: listing.bathrooms || 'N/A',
        cluster: listing.cluster || 'N/A',
        price: listing.price || 'N/A',
        year_built: listing.year_built || 'N/A',
        lot_size_sq_ft: listing.lot_size_sq_ft || 'N/A',
        lot_size_acres: listing.lot_size_acres || 'N/A'
      };
    }).filter(listing => listing !== null);
  };

  const formatZillowURL = (address) => {
    const formattedAddress = address
      .replace(/\s+/g, '-')
      .replace(/,/g, '')
      .replace(/--+/g, '-');

    return `https://www.zillow.com/homes/${formattedAddress}`;
  };

  const columns = [
    {
      name: 'Cluster',
      selector: row => row.cluster,
      sortable: true,
      width: '100px', // Adjust width for the Cluster column
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      width: '300px', // Set a minimum width, and allow the column to grow
    }
    ,
    {
      name: 'Bedrooms',
      selector: row => row.bedrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Bathrooms',
      selector: row => row.bathrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      width: '150px', // Set a width for the Price column
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '120px', // Set a width for the Year Built column
    },
    {
      name: 'Lot Size - Sq Ft',
      selector: row => row.lot_size_sq_ft,
      sortable: true,
      width: '150px', // Set a width for the Lot Size column
    },
    {
      name: 'Lot Size - Acres',
      selector: row => row.lot_size_acres,
      sortable: true,
      width: '150px', // Set a width for the Lot Size column
    },
    {
      name: 'Zillow Link',
      cell: row => (
        <a
          href={formatZillowURL(row.address)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <button style={{ padding: '5px 10px', background: '#0077cc', color: 'white', border: 'none', borderRadius: '5px' }}>
            View on Zillow
          </button>
        </a>
      ),
      width: '150px', // Set a width for the Zillow Link column
    }
  ];

  const transformClusterData = (clusters) => {
    return clusters.map(cluster => {
      return {
        cluster: cluster.Cluster ?? 'N/A', // Use nullish coalescing operator
        avgPrice: cluster['Avg Price'] ?? null,
        priceStdDev: cluster['Price Std Dev'] ?? null,
        avgSquareFootage: cluster['Avg Square Footage'] ?? null,
        squareFootageStdDev: cluster['Square Footage Std Dev'] ?? null,
        count: cluster['Count'] ?? 'N/A',
        avgPricePerSqFt: cluster['Avg Price per Sq Ft'] ?? null,
      };
    });
  };
  

  const clusterColumns = [
    {
      name: 'Cluster',
      maxWidth: '60px',
      selector: row => row.cluster,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Avg Price',
      selector: row => row.avgPrice ? row.avgPrice.toFixed(2) : 'N/A', // Check for null
      sortable: true
    },
    {
      name: 'Price Std Dev',
      selector: row => row.priceStdDev ? row.priceStdDev.toFixed(2) : 'N/A', // Check for null
      sortable: true
    },
    {
      name: 'Avg Sq Ft',
      selector: row => row.avgSquareFootage ? row.avgSquareFootage.toFixed(2) : 'N/A', // Check for null
      sortable: true
    },
    {
      name: 'Sq Ft Std Dev',
      selector: row => row.squareFootageStdDev ? row.squareFootageStdDev.toFixed(2) : 'N/A', // Check for null
      sortable: true
    },
    {
      name: 'Count',
      maxWidth: '50px',
      selector: row => row.count !== null && row.count !== undefined ? row.count : 'N/A', // Handle count being null
      sortable: true
    },
    {
      name: 'Avg $ per Sq Ft',
      selector: row => row.avgPricePerSqFt ? row.avgPricePerSqFt.toFixed(2) : 'N/A', // Check for null
      sortable: true
    }
  ];


  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const initialPropertyType = Object.keys(propertyTypes[category])[0];
    setSelectedPropertyType(initialPropertyType);
    setSelectedBeds([]);
  };
  
  const handlePropertyTypeChange = (type) => {
    setSelectedPropertyType(type);
    setSelectedBeds([]);
  };
  

  const handleBedsChange = (beds) => {
    setSelectedBeds((prevSelectedBeds) => {
      if (prevSelectedBeds.includes(beds)) {
        // Remove the bed count from the array
        return prevSelectedBeds.filter((b) => b !== beds);
      } else {
        // Add the bed count to the array
        return [...prevSelectedBeds, beds];
      }
    });
  };

  const handleClusterChange = (clusterCount) => {
    setSelectedCluster(clusterCount);
  };

  return (
    <div className="clustered-listings-container">
      {/* Selection Buttons */}
      <div className="section-buttons">
        <button
          className={selectedCategory === 'sale' ? 'active' : ''}
          onClick={() => handleCategoryChange('sale')}
        >
          For Sale
        </button>
        <button
          className={selectedCategory === 'rental' ? 'active' : ''}
          onClick={() => handleCategoryChange('rental')}
        >
          Rent
        </button>
      </div>

      {/* Property Types and Beds Selection */}
      <div className="product-types-container">
        {Object.keys(propertyTypes[selectedCategory]).map((type) => (
          <div key={type} className="product-type-container">
            <button
              className={`property-type-button ${selectedPropertyType === type ? 'active' : ''}`}
              onClick={() => handlePropertyTypeChange(type)}
            >
              {type}
            </button>
            {selectedPropertyType === type && (
              <div className="product-type-buttons">
                {propertyTypes[selectedCategory][type].map((beds) => (
                  <button
                    key={beds}
                    className={selectedBeds.includes(beds) ? 'active' : ''}
                    onClick={() => handleBedsChange(beds)}
                  >
                    {beds} Bed
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Cluster Selection Buttons */}
      <h3>Select Clusters</h3>
      <div className="clustered-listings-buttons">
        {[5, 10, 15].map((clusterCount) => (
          <button
            key={clusterCount}
            onClick={() => handleClusterChange(clusterCount)}
            className={`cluster-button ${selectedCluster === clusterCount ? 'active' : ''}`}
          >
            {clusterCount} Clusters
          </button>
        ))}
      </div>

      {/* Map and Cluster Summary Table Container */}
      <div className="map-and-summary-container">
        {/* Map Section */}
        <div className="map-section">
          <div id="map" className="map"></div>
        </div>

        {/* Cluster Summary Table Section */}
        <div className="cluster-summary-section">
          <DataTable
            title="Cluster Summary"
            columns={clusterColumns}
            data={clusterData}
            pagination
            noDataComponent="No listings for this filter"
            customStyles={{
              rows: {
                style: {
                  borderBottom: '1px solid #e0e0e0', // Light border for rows
                },
              },
              headCells: {
                style: {
                  borderBottom: '2px solid #e0e0e0', // Thicker border for header cells
                  fontWeight: 'bold',
                },
              },
            }}
          />
        </div>
      </div>

      {/* Listings Data Table */}
      <div className="listings-container" style={{ marginTop: '20px' }}>
        <DataTable
          title="Listings Data"
          columns={columns}
          data={transformedListings}
          pagination
          noDataComponent="No listings for this filter"
          responsive
          customStyles={{
            rows: {
              style: {
                borderBottom: '1px solid #e0e0e0', // Light border for rows
              },
            },
            headCells: {
              style: {
                borderBottom: '2px solid #e0e0e0', // Thicker border for header cells
                fontWeight: 'bold',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ClusteredListings;